import React from "react";
import img from "@img/development.svg";

export default props => (
	<div
		style={{
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			justifyContent: "center",
			minHeight: "360px"
		}}
	>
		<img alt="Иконка" width={100} src={img} />
		<p style={{ margin: "24px 0", textAlign: "center" }}>
			Страница находится в разработке
		</p>
	</div>
);
